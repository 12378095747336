<template>
  <div class="form-container">
    <div class="form">

      <MField
        :label="$t('form.country.label')"
        id="country"
        :required="true"
        :requirementText="$t('mandatory')"
        class="form-field"
      >
        <MAutocomplete
          v-model="formData.country"
          :items="countries"
          :placeholder="$t('form.country.placeholder')"
          :emptySearchLabel="$t('form.country.emptySearchLabel')"
          :invalid="formSubmitted && !countries.find((country) => country.code === formData.country)"
          :loading="isLocalesLoading"
          dataValueExpr="code"
          @update:modelValue="updatePurchaseCountry"
        />
      </MField>

      <MField
        class="form-field"
        :label="$t('form.selectDocument.label')"
        id="document"
        :required="true"
        :requirementText="$t('mandatory')"
        >
        <MDropdown
          v-model="formData.document"
          :items="getDocumentTypes"
          ariaLabelledBy="default"
          :isInvalid="formSubmitted && !formData.document"
          :placeholder="$t('form.selectDocument.placeholder')"
          :disabled="!formData.country"
        />
      </MField>

      <MField
        class="form-field"
        :label="$t('form.profil.label')"
        id="profile"
        :required="true"
        :requirementText="$t('mandatory')"
      >
        <MDropdown
          v-model="formData.profile"
          :items="profiles"
          ariaLabelledBy="default"
          :isInvalid="formSubmitted && !formData.profile"
          :placeholder="$t('form.profil.placeholder')"
        />
      </MField>

      <div class="form-row">
        <MField
          :label="$t('form.name.label')"
          id="name"
          :required="true"
          :requirementText="$t('mandatory')"
          class="form-field"
        >
          <MTextInput v-model="formData.name" :isInvalid="formSubmitted && !formData.name" :placeholder="$t('form.name.placeholder')" />
        </MField>
        <MField
          :label="$t('form.firstName.label')"
          id="firstName"
          :required="true"
          :requirementText="$t('mandatory')"
          class="form-field"
        >
          <MTextInput v-model="formData.firstName" :isInvalid="formSubmitted && !formData.firstName" :placeholder="$t('form.firstName.placeholder')" />
        </MField>
      </div>

      <MField
        :label="$t('form.email.label')"
        id="email"
        :required="true"
        :requirementText="$t('mandatory')"
        class="form-field"
      >
        <MTextInput v-model="formData.email" :isInvalid="formSubmitted && !formData.email" :placeholder="$t('form.email.placeholder')" />
      </MField>

      <MCheckbox
        id="checkbox"
        v-model="formData.agree"
        :isInvalid="formSubmitted && !formData.agree"
      >
        <template #label class="checkbox-text">
          <p>{{ $t('form.checkbox.description') }}</p>
          <MLink
              v-bind="{ router: { name: 'router-link', props: { to: { name: 'privacy' } } } }"
          >
            {{ $t('form.checkbox.linkName') }}
          </MLink>
        </template>
      </MCheckbox>

      <MButton :label="$t('form.submitButton')" :disabled="isSubmitting" @click="handleSubmit" />
    </div>
    <MNotification v-if="formError" type="danger" class="notification">
          <p>
            {{ $t('failedFormSubmitNotificationMessage') }}
          </p>
    </MNotification>
  </div>
</template>

<script setup>
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import { CustomerRequestService } from '@/service/CustomerRequestService';
import { useCountries } from '@/composables/useCountries';

  const emit = defineEmits('form-submitted')
  
  const store = useStore();
  const { t } = useTranslation();
  
  const { countries, isLocalesLoading } = useCountries();
  
  const documentTypes = ref([]);
  const formData = reactive({
    document: null,
    profile: null,
    name: '',
    firstName: '',
    country: '',
    email: '',
    agree: false,
  });
  
  const isSubmitting = ref(false);
  const formSubmitted = ref(false);
  const formError = ref(false);

  const product = computed(() => store.getters.productDetails || {});

  const getDocumentTypes = computed(() => {
    let i = 1;
    let types = [];
    documentTypes.value
      .filter((type, _, array) => 
        type !== 'IMS' || !array.includes('IMD')
      )
      .forEach((documentType) => {
        types.push(
          {id: i, value: documentType, label: t('documentTypeLabel.' + documentType)}
        )
        i++;
      }
    );
    return types;
  });

  const profiles = computed(() => [
    { id: 1, value: 'PROFESSIONAL', label: t('form.profil.value.professional') },
    { id: 2, value: 'INDIVIDUAL', label: t('form.profil.value.individual') }
  ]);
  
  async function handleSubmit() {
    if (isSubmitting.value) return;

    formSubmitted.value = true;
    const country = countries.value.find((country) => country.code === formData.country);
    if (
        formData.document &&
        formData.profile &&
        formData.name &&
        formData.firstName &&
        country &&
        formData.email &&
        formData.agree
    ) {
      isSubmitting.value = true;

      try {
        const success = await CustomerRequestService.missingDocumentRequest({...formData, country: country.label}, product.value);

        if (success) {
          formError.value = false;
          emit('form-submitted');
        } else {
          formError.value = true;
          throw new Error('Failed to submit form');
        }
      } catch (error) {
        formError.value = true;
      } finally {
        isSubmitting.value = false;
      }
    }
  }

  function updatePurchaseCountry(listboxValue, getSelectedItems) {
    formData.document = null;
    if (!!listboxValue)
      fetchDocumentTypes(product.value.id, listboxValue);
  }

  async function fetchDocumentTypes(productId, purchaseCountry) {
    try {
      const response = await CustomerRequestService.getAllowedDocumentTypes(productId, purchaseCountry);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      documentTypes.value = await response.json();
    } catch (e) {
      error.value = e
      console.error('Erreur lors de la récupération des types de documents:', e)
    }
  }

</script>

<style scoped>
.form-container {
  background-color: #ffffff;
  margin: 50px auto;
  border-radius: 10px;
  max-width: 1008px;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
}

.form-field {
  display: flex;
  flex-direction: column;
  text-align: left !important;
}

@media (max-width: 768px) {
  .form-container {
    width: 90%;
    padding: 20px;
  }

  .form-row {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 769px) {
  .form-container {
    padding: 40px 60px;
  }
}

.mc-checkbox__label p {
  font-weight: bold!important;
}
.mc-checkbox__label a {
  font-weight: bold!important;
}
.notification {
  margin-top: 20px;
}
</style>
