import { useTranslation } from 'i18next-vue';
import { computed, ref, watchEffect } from 'vue';
import i18nIsoCountries from 'i18n-iso-countries';

const loadedLocales = ref({});
const isLocalesLoading = ref(false);

async function loadIsoCountryLocale(lang) {
  if (loadedLocales.value[lang]) return;
  const localeImport = await import(`i18n-iso-countries/langs/${lang}.json`);
  i18nIsoCountries.registerLocale(localeImport.default);
  loadedLocales.value[lang] = true;
}

export function useCountries() {
  const { i18next } = useTranslation(); 
  
  watchEffect(async () => {
    isLocalesLoading.value = true;
    const lang = i18next.language;
    await loadIsoCountryLocale(lang);
    isLocalesLoading.value = false;
  });

  const countries = computed(() => {
    if (isLocalesLoading.value) {
      // return an empty array while the locales are loading
      return [];
    }
    const obj = i18nIsoCountries.getNames(i18next.language, { select: 'official' }) || {};
    return Object.entries(obj).map(([code, label]) => ({ code, label }));
  });

  return {
    countries,
    isLocalesLoading
  };
}